
import { defineComponent } from "vue";

export default defineComponent({
  name: "kt-widget-5",
  components: {},
  props: {
    widgetClasses: String
  }
});
